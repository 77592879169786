import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import GlobalStateProvider from "../context/provider"
import ContentWrapper from "../styles/contentWrapper"
import Underlining from "../styles/underlining"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledSection = styled.section`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 0 var(--edge-padding);
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  h1 {
    font-size: 1.5rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    max-width: 36rem;
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const NotFoundPage = () => {
  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          title="404: Not found"
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <StyledSection>
          <StyledContentWrapper>
            <h1 data-testid="heading">Page Not Found</h1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>Sorry, you weren't supposed to see this!</p>
            <p>
              Not sure how this happened, but you should{" "}
              <Link to="/" aria-label="home">
                <Underlining>head on home</Underlining>
              </Link>
              .
            </p>
          </StyledContentWrapper>
        </StyledSection>
      </Layout>
    </GlobalStateProvider>
  )
}

export default NotFoundPage
